import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import AddRole from "./views/rolePermission/addRole";

// session pages
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(
  lazy(() => import("app/views/sessions/JwtRegister"))
);
const ForgotPassword = Loadable(
  lazy(() => import("app/views/sessions/ForgotPassword"))
);
const OTPpass = Loadable(lazy(() => import("app/views/sessions/otp")));
const ResetPassword = Loadable(
  lazy(() => import("app/views/sessions/ResetPassword"))
);

// echart page
const AppEchart = Loadable(
  lazy(() => import("app/views/charts/echarts/AppEchart"))
);

// dashboard page
const Dashboard = Loadable(lazy(() => import("app/views/dashboard/Dashboard")));
const ManageUser = Loadable(
  lazy(() => import("app/views/manageUser/ManageUser"))
);
const ManageCompany = Loadable(
  lazy(() => import("app/views/manageCompany/ManageCompany"))
);
const ManagePayment = Loadable(
  lazy(() => import("app/views/managePayment/ManagePayment"))
);
const RolePermission = Loadable(
  lazy(() => import("app/views/rolePermission/rolePermission"))
);
const ManageInternalUser = Loadable(
  lazy(() => import("app/views/manageInternalUser/ManageInternalUser"))
);

const ManageWorkerpool = Loadable(
  lazy(() => import("app/views/workerpoolManagement/ManageWpUser"))
);
const ManageTalentpool = Loadable(
  lazy(() => import("app/views/talentpoolManagement/ManageTpUser"))
);
const ManageBackgroundScreening = Loadable(
  lazy(() => import("app/views/backgroundScreenManagement/ManageScreening"))
);
const ManageReport = Loadable(
  lazy(() => import("app/views/reportManagement/ManageReport"))
);
const ContentManagement = Loadable(
  lazy(() => import("app/views/contentManagement/ContentManagement"))
);
const EditPage = Loadable(
  lazy(() => import("app/views/contentManagement/editPage"))
);
const FooterManagement = Loadable(
  lazy(() => import("app/views/footerManagement/FooterManagement"))
);
const EditFooter = Loadable(
  lazy(() => import("app/views/footerManagement/editFooter"))
);
const ManageFaq = Loadable(
  lazy(() => import("app/views/faqManagement/ManageFaq"))
);
const MyAccount = Loadable(lazy(() => import("app/views/myAccount/MyAccount")));
const WalletManagement = Loadable(
  lazy(() => import("app/views/manageWallet/WalletManagement"))
);
const ManageSupport = Loadable(
  lazy(() => import("app/views/manageSupport/ManageSupport"))
);
const ManageSubscription = Loadable(
  lazy(() => import("app/views/manageSubscription/ManageSubscription"))
);
const EditMembership = Loadable(
  lazy(() => import("app/views/manageSubscription/editPage"))
);
const ManageFeatureSubscription = Loadable(
  lazy(() => import("app/views/manageSubscription/ManageFeatureSubscription"))
);
const ManageFeatureValueSubscription = Loadable(
  lazy(() =>
    import("app/views/manageSubscription/ManageFeatureValueSubscription")
  )
);

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      // dashboard route
      {
        path: "/dashboard",
        element: <Dashboard />,
        auth: authRoles.admin,
      },

      {
        path: "/manage-user",
        element: <ManageUser />,
        auth: authRoles.admin,
      },

      {
        path: "/manage-company",
        element: <ManageCompany />,
        auth: authRoles.admin,
      },

      {
        path: "/manage-payment",
        element: <ManagePayment />,
        auth: authRoles.admin,
      },

      {
        path: "/roles-permission",
        element: <RolePermission />,
        auth: authRoles.admin,
      },

      {
        path: "/roles-permission/add-role",
        element: <AddRole />,
        auth: authRoles.admin,
      },

      {
        path: "/roles-permission/view-role/:id",
        element: <AddRole />,
        auth: authRoles.admin,
      },

      {
        path: "/roles-permission/edit-role/:id",
        element: <AddRole />,
        auth: authRoles.admin,
      },

      {
        path: "/manage-internal-user",
        element: <ManageInternalUser />,
        auth: authRoles.admin,
      },

      {
        path: "/manage-wp",
        element: <ManageWorkerpool />,
        auth: authRoles.admin,
      },

      {
        path: "/manage-tp",
        element: <ManageTalentpool />,
        auth: authRoles.admin,
      },
      {
        path: "/manage-background-screening",
        element: <ManageBackgroundScreening />,
        auth: authRoles.admin,
      },
      {
        path: "/manage-report",
        element: <ManageReport />,
        auth: authRoles.admin,
      },
      {
        path: "/content-management",
        element: <ContentManagement />,
        auth: authRoles.admin,
      },

      {
        path: "/content-management/edit-page/:key/:id",
        element: <EditPage />,
        auth: authRoles.admin,
      },

      {
        path: "/footer-management/edit-footer/:key/:id",
        element: <EditFooter />,
        auth: authRoles.admin,
      },

      {
        path: "/faq-management",
        element: <ManageFaq />,
        auth: authRoles.admin,
      },

      {
        path: "/my-account",
        element: <MyAccount />,
        auth: authRoles.admin,
      },

      {
        path: "/manage-wallet",
        element: <WalletManagement />,
        auth: authRoles.admin,
      },

      {
        path: "/manage-support",
        element: <ManageSupport />,
        auth: authRoles.admin,
      },

      {
        path: "/manage-subscription",
        element: <ManageSubscription />,
        auth: authRoles.admin,
      },

      {
        path: "/manage-subscription/edit-membership/:id",
        element: <EditMembership />,
        auth: authRoles.admin,
      },

      {
        path: "/manage-subscription/manage-feature-subscription",
        element: <ManageFeatureSubscription />,
        auth: authRoles.admin,
      },

      {
        path: "/manage-subscription/manage-feature-value-subscription/:id",
        element: <ManageFeatureValueSubscription />,
        auth: authRoles.admin,
      },

      // e-chart rooute
      {
        path: "/charts/echarts",
        element: <AppEchart />,
        auth: authRoles.editor,
      },
      {
        path: "/footer-management",
        element: <FooterManagement />,
        auth: authRoles.editor,
      },
    ],
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },
  { path: "/session/otp", element: <OTPpass /> },
  { path: "/session/reset-password", element: <ResetPassword /> },

  { path: "/", element: <Navigate to="dashboard" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
